import { component } from 'picoapp'
import choozy from 'choozy'
import { on, rect } from 'martha'
import { hover } from '../lib/hover'
import gsap from 'gsap'

export default component((node, ctx) => {
  const offClick = on(node, 'click', (ev) => {
    const event = node.dataset.emit
    if (event) {
      ev.preventDefault()
      ctx.emit(event)
    }
  })

  if (ctx.getState().isDevice) {
    return offClick
  }

  const { circle } = choozy(node)
  const tl = gsap.timeline({ paused: true })

  const offHover = hover(node, {
    enter: ({ clientX, clientY }) => {
      const bounds = rect(node)

      const px = clientX - bounds.left
      const py = clientY - bounds.top

      const x = px > bounds.width / 2 ? px : bounds.width - px
      const y = py > bounds.height / 2 ? py : bounds.height - py

      const radius = Math.sqrt(x * x + y * y)

      tl.clear()
        .set(circle, {
          width: radius * 2,
          height: radius * 2,
          marginLeft: -radius,
          marginTop: -radius,
          left: px,
          top: py,
          scale: 0,
          opacity: 0,
        })
        .to(
          circle,
          {
            duration: 0.35,
            ease: 'power3',
            opacity: 1,
          },
          0,
        )
        .to(
          circle,
          {
            duration: 0.7,
            ease: 'power3',
            scale: 1.01,
          },
          0,
        )
        .restart()
    },
    leave: () => {
      tl.clear()
        .to(
          circle,
          {
            duration: 0.35,
            ease: 'power3',
            opacity: 0,
          },
          0,
        )
        .restart()
    },
  })

  return () => {
    offHover()
    offClick()
  }
})
