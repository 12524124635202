import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import Lenis from '@studio-freight/lenis'
import { listen } from 'quicklink'
import { on, rect, each, qs } from 'martha'
import { fonts } from './lib/fonts'
import { create } from './lib/pjax'
import { scroll } from './lib/scroll'
import { fade } from './lib/transitions'
import app from './app'

/** Prevent automatic page location restoration
 * https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration#prevent_automatic_page_location_restoration
 */
if (history.scrollRestoration) {
  history.scrollRestoration = 'manual'
}

if (qs('#svh').offsetHeight > 0) {
  document.documentElement.classList.add('svh')
}

/**
 * Register any gsap plugins
 */
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

/**
 * Initializes link prefetching based on visibility in viewport for
 * instant next-page navigation via quicklink (production-only)
 * https://getquick.link/
 */
if (process.env.NODE_ENV === 'production') {
  listen()
}

/**
 * Waits for all fonts to load before initializing
 */
fonts([
  { family: 'Tenor Sans', options: { weight: 400, style: 'normal' } },
  { family: 'IBM Plex Sans', options: { weight: 400, style: 'normal' } },
  { family: 'IBM Plex Sans', options: { weight: 400, style: 'normal' } },
  { family: 'IBM Plex Sans', options: { weight: 700, style: 'italic' } },
  { family: 'IBM Plex Sans', options: { weight: 700, style: 'italic' } },
]).then(() => {
  /**
   * Create Lenis instance for smooth scrolling using native scroll
   */
  window.lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    direction: 'vertical',
    gestureDirection: 'vertical',
    smooth: true,
    mouseMultiplier: 1,
    smoothTouch: false,
    touchMultiplier: 2,
    infinite: false,
  })

  /**
   * Attaches global resize event
   */
  on(window, 'resize', () => resize())

  /**
   * Attaches global scroll event
   */
  on(window, 'scroll', handleScroll)

  /**
   * Attaches global mousemove event
   */
  on(document, 'pointermove', mousemove)

  /**
   * Starts global requestAnimationFrame loop
   * https://greensock.com/docs/v3/GSAP/gsap.ticker
   */
  gsap.ticker.add((time, deltaTime, frame) => {
    /**
     * Updates scroll every frame
     */
    lenis.raf(time * 1000)

    /**
     * Emits tick event and update state
     */
    app.emit('tick', { time, deltaTime, frame })
  })

  /**
   * Initializes animated page transitions
   */
  const pjax = create({
    transitions: {
      default: fade,
    },
  })

  /**
   * Mounts application, triggers lifecycle events, and restores scroll position
   * before every page navigation entrance (including the initial load)
   */
  pjax.on('beforeEnter', (props) => {
    app.mount()
    app.emit('beforeEnter', null, props)
    resize(true)
    scroll()
    app.emit('initScrollTriggers')
  })

  /**
   * Broadcast an event when a page navigation is triggered
   */
  pjax.on('beforeLeave', (props) => {
    if (app.getState().isMenuOpen) {
      app.emit('menu:toggle', { isMenuOpen: false })
    }

    app.emit('beforeLeave', null, props)
  })

  /**
   * Unmounts application before after page navigation exit
   */
  pjax.on('afterLeave', (props) => {
    each(ScrollTrigger.getAll(), (instance) => instance.kill())
    app.unmount()
    app.emit('afterLeave', null, props)
  })

  pjax.on('samePage', () => {
    if (app.getState().isMenuOpen) {
      app.emit('menu:toggle', { isMenuOpen: false })
    }
  })
})

/**
 * Handles resize event
 */
function resize(force = false) {
  const s = rect(qs('#vh'))
  const { ww, wh } = app.getState()

  if (force || s.width !== ww || s.height !== wh) {
    const savedScrollY = window.scrollY
    window.scroll(0, 0)
    ScrollTrigger.refresh(true)

    app.emit('resize', {
      ww: s.width,
      wh: s.height,
    })

    window.scroll(0, savedScrollY)
  }
}

/**
 * Handles scroll event
 */
function handleScroll() {
  let lastScrollY = app.getState().scrollY
  let scrollY = window.scrollY

  app.hydrate({
    lastScrollY,
    scrollY,
    scrollDirection: lastScrollY < scrollY ? 1 : -1,
  })
}

/**
 * Handles mousemove event
 */
function mousemove(ev) {
  app.emit('mousemove', { mouseX: ev.x, mouseY: ev.y, mouseEvent: ev })
}
