import { component } from 'picoapp'
import gsap from 'gsap'

export default component((node, ctx) => {
  ctx.on('initScrollTriggers', () => {
    gsap.to(node, {
      rotation: 120,
      ease: 'none',
      scrollTrigger: {
        trigger: node,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1,
        invalidateOnRefresh: true,
      },
    })
  })

  return () => {}
})
