import { component } from 'picoapp'
import { add, each, remove } from 'martha'
import choozy from 'choozy'
import gsap from 'gsap'
import { createFocusTrap } from 'focus-trap'

export default component((node, ctx) => {
  const { items } = choozy(node)
  const focusTrap = createFocusTrap(node, { escapeDeactivates: false })

  each(items, (item) => {
    item.refs = choozy(item, 'js:item-')
    item.tl = gsap.timeline({ paused: true })
  })

  const tl = gsap.timeline({ paused: true })

  ctx.on('menu:reset', () => reset())
  ctx.on('menu:toggle', ({ isMenuOpen }) => (isMenuOpen ? open() : close()))

  function open() {
    node.scrollTop = 0

    focusTrap.activate()

    add(document.body, 'overflow-hidden')

    let i = 0

    tl.clear()
      .set(node, { pointerEvents: 'auto' })
      .to(node, { '--b': 0, duration: 1.25, ease: 'power3.inOut' }, 0)
      .to(
        items,
        {
          stagger: {
            each: 0.05,
            onStart() {
              const item = items[i]
              const { num, slash, title } = item.refs

              item.tl.clear().set(title, {
                yPercent: 100,
              })

              if (num && slash) {
                item.tl
                  .set(num, {
                    yPercent: 100,
                  })
                  .set(slash, {
                    transformOrigin: 'bottom',
                    scaleY: 0,
                  })
              }

              item.tl.set(item, { opacity: 1 }).to(
                [num, title].filter(Boolean),
                {
                  duration: 0.9,
                  ease: 'expo',
                  yPercent: 0,
                  stagger: 0.04,
                },
                0,
              )

              if (slash) {
                item.tl.to(
                  slash,
                  {
                    duration: 0.9,
                    ease: 'expo',
                    scaleY: 1,
                  },
                  0.02,
                )
              }

              item.tl.restart()

              i = i + 1
            },
          },
        },
        0.6,
      )
      .restart()
  }

  function close() {
    focusTrap.deactivate()

    remove(document.body, 'overflow-hidden')

    let i = 0

    tl.clear()
      .set(node, { pointerEvents: 'none' })
      .to(
        items,
        {
          stagger: {
            each: 0.05,
            onStart() {
              const item = items[i]
              const { num, slash, title } = item.refs

              item.tl.clear()

              if (num) {
                item.tl.set(slash, {
                  transformOrigin: 'top',
                })
              }

              item.tl.to(
                [num, title].filter(Boolean),
                {
                  duration: 1.25,
                  ease: 'power3.inOut',
                  yPercent: -100,
                  stagger: 0.04,
                },
                'hide',
              )

              if (slash) {
                item.tl.to(
                  slash,
                  {
                    duration: 1.25,
                    ease: 'power3.inOut',
                    scaleY: 0,
                  },
                  0.02,
                )
              }

              item.tl.set(item, { opacity: 0 }).restart()

              i = i + 1
            },
          },
        },
        0,
      )
      .to(node, { '--b': 100, duration: 1.25, ease: 'power3.inOut' }, 0.2)
      .restart()
  }

  function reset() {
    focusTrap.deactivate()

    remove(document.body, 'overflow-hidden')

    tl.clear()
      .set(node, { '--b': 100, pointerEvents: 'none' })
      .set(items, { opacity: 0 })
      .restart()
  }
})
