import { component } from 'picoapp'
import Vlitejs from 'vlitejs'
// import VlitejsSubtitle from 'vlitejs/dist/plugins/subtitle'

// Vlitejs.registerPlugin('subtitle', VlitejsSubtitle)

export default component((node, ctx) => {
  let vlite = new Vlitejs(node, {
    // plugins: ['subtitle'],
    options: {
      poster: node.dataset.poster,
      bigPlay: true,
      playsinline: true,
      progressBar: true,
      muted: false,
    },
    onReady(player) {
      let { container } = player.elements
      // let disableCaptionsButton = qs('[data-language="off"]', container)

      // if (disableCaptionsButton) {
      //   disableCaptionsButton.click()
      // }

      if (node.dataset.padding) {
        container.style.paddingTop = node.dataset.padding
      }

      ctx.emit('resize')
    },
  })

  return () => {
    vlite.player.destroy()
  }
})
