import choozy from 'choozy'
import gsap from 'gsap'

export default function createTitle(el) {
  let refs = choozy(el, 'js:title-')
  let tl = gsap.timeline()

  return {
    el,
    refs,
    tl,
    hide() {
      let { num, line, text } = refs

      tl.clear()
        .set(line, { transformOrigin: 'left' })
        .to(
          [num, text],
          {
            duration: 1,
            ease: 'expo',
            yPercent: -100,
          },
          'hide',
        )
        .to(
          line,
          {
            duration: 1,
            ease: 'expo',
            scaleX: 0,
          },
          'hide',
        )
    },
    show() {
      let { num, line, text } = refs

      tl.clear()
        .set([num, text], { yPercent: 100 })
        .set(line, { scaleX: 0, transformOrigin: 'left' })
        .set(el, { autoAlpha: 1 })
        .to(
          [num, text],
          {
            duration: 1,
            ease: 'expo',
            yPercent: 0,
          },
          'show',
        )
        .to(
          line,
          {
            duration: 1,
            ease: 'expo',
            scaleX: 1,
            delay: 0.1,
          },
          'show',
        )
    },
  }
}
