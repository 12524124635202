import { component } from 'picoapp'
import choozy from 'choozy'
import { on, toggle, each, index } from 'martha'
import EmblaCarousel from 'embla-carousel'
// import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

export default component((node, ctx) => {
  const { carousel, next, prev, btns } = choozy(node)

  const embla = EmblaCarousel(
    carousel,
    {
      align: 'start',
      containScroll: 'keepSnaps',
    },
    // [WheelGesturesPlugin({
    //   element: node,
    //   forceWheelAxis: 'x',
    // })],
  )

  const events = [
    on(prev, 'click', () => embla.scrollPrev()),
    on(next, 'click', () => embla.scrollNext()),
    on(btns, 'click', ({ currentTarget: t }) => embla.scrollTo(index(t))),
  ]

  embla.on('select', () => {
    const i = embla.selectedScrollSnap()

    if (btns) {
      each(btns, (btn, j) => toggle(btn, '!opacity-100', j === i))
    }
  })

  return () => {
    embla.destroy()
    events.map((off) => off())
  }
})
