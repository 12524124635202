import { component } from 'picoapp'
import choozy from 'choozy'
import { on, add, remove } from 'martha'
import gsap from 'gsap'
import { newsletterSubscribe } from '../lib/newsletter-subscribe'

export default component((node, ctx) => {
  let { message, form, success, error } = choozy(node)

  on(form, 'submit', async (ev) => {
    ev.preventDefault()

    showLoader()

    const json = {
      'form-name': `${form.dataset.formName} - ${form.subject.value}`,
    }

    for (let i = 0; i < form.elements.length; i++) {
      let el = form.elements[i]
      if (el.type === 'submit') continue
      json[el.name] = el.type === 'checkbox' ? el.checked : el.value
    }

    const promises = [
      process.env.NODE_ENV === 'production'
        ? fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode(json),
          })
        : new Promise((resolve) => {
            console.log('Mock Submission', json)
            setTimeout(resolve, 1000)
          }),
      fetch('/.netlify/functions/sc', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ p: window.btoa(form.email.value) }),
      }),
    ]

    if (form?.subscribe?.checked) {
      promises.push(
        newsletterSubscribe({
          audienceId: '97854a1ed7',
          email: form.email.value,
        })
          .then(() => {
            // app.ga.send('event', { ec: 'Lead', ea: 'Newsletter Signup' })
          })
          .catch(() => {}),
      )
    }

    Promise.all(promises)
      .then(() => {
        showMessage()
      })
      .catch(() => {
        add(success, 'hidden')
        remove(error, 'hidden')
        showMessage()
      })
  })

  function showLoader() {
    add(form, 'opacity-40 pointer-events-none')
  }

  function showMessage() {
    node.scrollIntoView(true)

    gsap.to(form, {
      duration: 0.5,
      scale: 0.95,
      autoAlpha: 0,
      ease: 'quint',
    })

    gsap.set(message, { y: 15 })

    gsap.to(message, {
      duration: 1,
      delay: 0.25,
      autoAlpha: 1,
      y: 0,
      ease: 'quint',
    })
  }
})

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}
