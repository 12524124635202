import choozy from 'choozy'
import gsap from 'gsap'
import { createMachine, interpret, assign } from '@xstate/fsm'
import { on, qs, rect } from 'martha'

import createNav from './nav'
import createLink from './link'
import createTitle from './title'

export default function createSidebar(node, ctx, trigger) {
  let { nav, links, titles } = setupElements(node)
  let events = []

  let sidebarService = interpret(
    createMachine(
      {
        id: 'sidebar',
        initial: 'idle',
        context: {
          prevIdleIndex: -1,
          idleIndex: -1,
          prevHoverIndex: -1,
          hoverIndex: 0,
        },
        states: {
          idle: {
            on: {
              UPDATE: {
                actions: ['setIdleIndex', 'handleSectionsUpdate'],
              },
              MOUSE_ENTER: {
                target: 'hovered',
                actions: ['setHoverIndexFromIdle', 'handleHover'],
              },
            },
          },
          hovered: {
            on: {
              UPDATE: {
                actions: ['setIdleIndex', 'handleSectionsUpdateFromHovered'],
              },
              MOUSE_ENTER: {
                actions: ['setHoverIndex', 'handleHover'],
              },
              CLICK: {
                actions: ['setIdleIndexOnClick', 'handleLinkClick'],
              },
              MOUSE_LEAVE: {
                target: 'idle',
                actions: 'restoreIdle',
              },
            },
          },
        },
      },
      {
        actions: {
          setIdleIndex: assign((context, event) => ({
            ...context,
            prevIdleIndex: context.idleIndex,
            idleIndex: event.index,
          })),
          setHoverIndex: assign((context, event) => ({
            ...context,
            prevHoverIndex: context.hoverIndex,
            hoverIndex: event.index,
          })),
          setHoverIndexFromIdle: assign((context, event) => ({
            ...context,
            prevHoverIndex: context.idleIndex,
            hoverIndex: event.index,
          })),
          setIdleIndexOnClick: assign((context, event) => ({
            ...context,
            prevIdleIndex: context.idleIndex,
            idleIndex: event.index,
          })),
          handleSectionsUpdate: ({ prevIdleIndex, idleIndex }) => {
            links.update({
              from: prevIdleIndex,
              to: idleIndex,
            })
            titles.update({
              from: prevIdleIndex,
              to: idleIndex,
            })
          },
          handleSectionsUpdateFromHovered: ({ prevIdleIndex, idleIndex }) => {
            if (ctx.getState().isAutoScrolling) return
            links.update({
              from: prevIdleIndex,
              to: idleIndex,
            })
          },
          handleHover: ({ prevHoverIndex, hoverIndex }) => {
            titles.update({
              from: prevHoverIndex,
              to: hoverIndex,
            })
          },
          restoreIdle: ({ hoverIndex, idleIndex }) => {
            titles.update({
              from: hoverIndex,
              to: idleIndex,
            })
          },
          handleLinkClick: ({ prevIdleIndex, idleIndex }, { index }) => {
            gsap.to(window, {
              ease: 'power2.inOut',
              duration: 1.5,
              scrollTo:
                trigger.rect.top +
                (trigger.rect.height / links.length) * index +
                1,
              onStart: () => {
                ctx.hydrate({ isAutoScrolling: true })
              },
              onComplete: () => {
                ctx.hydrate({ isAutoScrolling: false })
              },
            })
            links.update({
              from: prevIdleIndex,
              to: idleIndex,
            })
          },
        },
      },
    ),
  ).start()

  links.forEach((link, i) => {
    let offEnter = on(link.el, 'mouseenter', () => {
      sidebarService.send({
        type: 'MOUSE_ENTER',
        index: i,
      })
    })

    let offClick = on(link.el, 'click', (ev) => {
      ev.preventDefault()
      sidebarService.send({
        type: 'CLICK',
        index: i,
      })
    })

    events.push(offEnter, offClick)
  })

  let offNav = on(nav.el, 'mouseleave', () => {
    sidebarService.send({ type: 'MOUSE_LEAVE' })
  })

  ctx.on('sidebar:update', (_state, index) => {
    sidebarService.send({
      type: 'UPDATE',
      index,
    })
  })

  return {
    unmount: () => {
      events.forEach((off) => off())
      offNav()
    },
  }
}

function setupElements(node) {
  let refs = choozy(node)

  refs.nav = createNav(refs.nav)
  refs.links = refs.links.map(createLink)

  refs.links.update = ({ from, to }) => {
    if (from === to) return

    let delay = 0.1

    if (from > -1) {
      refs.links[from].hide()
    } else {
      delay = 0.1
      refs.nav.show()
    }

    if (to > -1) {
      refs.links[to].show({ delay })
    } else {
      refs.nav.hide()
    }
  }

  refs.titles = refs.titles.map(createTitle)
  refs.titles.update = ({ from, to }) => {
    if (from === to) return

    refs.titles.forEach((title, i) => {
      if (i !== from && i !== to) {
        gsap.set(title.el, { autoAlpha: 0 })
      }
    })

    from > -1 && refs.titles[from].hide()
    to > -1 && refs.titles[to].show()
  }

  return refs
}
