import { component } from 'picoapp'
import choozy from 'choozy'
import { qs, on } from 'martha'
import { clipboardCopy } from '../lib/clipboard-copy'
import sharable from '../lib/sharable'

export default component((node, ctx) => {
  const { btns } = choozy(node)
  const copyBtn = qs('[data-copy]', node)

  btns.forEach((btn) =>
    btn.setAttribute(
      'data-url',
      window.location.href.replace(
        'http://localhost:3000',
        'https://mylo-unleather.com',
      ),
    ),
  )

  on(copyBtn, 'click', () => {
    clipboardCopy(copyBtn.dataset.url)
    copyBtn.textContent = 'Copied!'
    setTimeout(() => {
      copyBtn.textContent = 'Copy Link'
    }, 2000)
  })

  sharable()
})
