import gsap from 'gsap'
import { qs } from 'martha'

export function scroll({
  href = window.location.href,
  duration = 0,
  pushState = false,
} = {}) {
  if (href.indexOf('#') > -1) {
    const url = new URL(href, window.location.origin)
    const target = qs(url.hash)

    if (target) {
      target.focus({ preventScroll: true })

      if (pushState) {
        window.history.pushState(null, '', url.toString())
      }

      const headerHeight = parseFloat(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--header-height')
          .slice('0, -2'),
      )

      const y = Math.min(
        target.offsetTop - headerHeight,
        document.body.offsetHeight - window.innerHeight,
      )

      const scroll = { y: window.scrollY }

      requestAnimationFrame(() => {
        if (duration > 0) {
          gsap.to(scroll, {
            y: y < 0 ? 0 : y,
            duration,
            ease: 'expo.inOut',
            onUpdate: () => window.lenis.scrollTo(scroll.y),
          })
        } else {
          window.lenis.scrollTo(y)
        }
      })
    }
  }
}
