import choozy from 'choozy'
import gsap from 'gsap'

export default function createLink(el) {
  let refs = choozy(el, 'js:link-')
  let tl = gsap.timeline()

  return {
    el,
    refs,
    tl,
    hide() {
      let { stroke } = refs
      // let length = Math.round(stroke.getTotalLength())
      let length = 91

      tl.clear()
        .set(stroke, {
          strokeDashoffset: length * 2,
          transformOrigin: 'center',
        })
        .to(stroke, {
          rotation: 90,
          strokeDashoffset: length,
          duration: 1,
          ease: 'quart',
        })
    },
    show({ delay = 0.5 }) {
      let { stroke, circle } = refs
      // let length = `${Math.round(stroke.getTotalLength())}px`
      let length = '91px'

      tl.clear()
        .set(stroke, {
          strokeDashoffset: length,
          strokeDasharray: length,
          transformOrigin: 'center',
          rotation: -180,
        })
        .set(circle, { autoAlpha: 1 })
        .to(stroke, {
          rotation: -90,
          strokeDashoffset: 0,
          duration: 1,
          ease: 'quart',
          delay,
        })
    },
  }
}
