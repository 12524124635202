import { component } from 'picoapp'
import choozy from 'choozy'
import EmblaCarousel from 'embla-carousel'
import ClassNames from 'embla-carousel-class-names'
import { on, add, remove, each, has, wrap } from 'martha'
import gsap from 'gsap'

export default component((node, ctx) => {
  const refs = choozy(node)
  const events = []
  const strokeLength = 63
  const autoplayDuration = +node.dataset.autoplayDuration
  let isAutoplayEnabled = true

  const embla = EmblaCarousel(refs.carousel, { loop: false }, [
    ClassNames({ selected: '!opacity-100' }),
  ])

  embla.on('select', updateButtons)

  refs.buttons.forEach((button, i) => {
    const off = on(button, 'click', () => {
      isAutoplayEnabled = false
      embla.scrollTo(i)
    })
    events.push(off)
    button.refs = choozy(button)
    button.tl = gsap.timeline({
      defaults: { duration: 1, ease: 'quart' },
    })
  })

  updateButtons()

  function updateButtons() {
    let selected = embla.selectedScrollSnap()
    refs.buttons.forEach((button, i) => {
      const { bgCircle, fgCircle } = button.refs
      const circles = [bgCircle, fgCircle]

      if (i !== selected) {
        button.tl.set(circles, {
          strokeDashoffset: strokeLength,
          strokeDasharray: strokeLength,
          transformOrigin: 'center',
        })
      }

      if (i === selected) {
        add(button, '!opacity-100')
        add(button.refs.indicator, '!scale-100')

        button.tl
          .clear()
          .set(circles, {
            strokeDashoffset: strokeLength,
            strokeDasharray: strokeLength,
            transformOrigin: 'center',
            rotation: -180,
          })
          .to(isAutoplayEnabled ? bgCircle : circles, {
            rotation: -90,
            strokeDashoffset: 0,
          })

        if (isAutoplayEnabled) {
          button.tl.set(fgCircle, { rotation: -90 }).to(fgCircle, {
            strokeDashoffset: 0,
            ease: 'none',
            duration: autoplayDuration,
            onComplete: () => {
              if (isAutoplayEnabled) {
                embla.scrollTo(wrap(selected + 1, refs.buttons.length))
              }
            },
          })
        }
      } else if (has(button, '!opacity-100')) {
        remove(button, '!opacity-100')
        remove(button.refs.indicator, '!scale-100')

        button.tl
          .clear()
          .set(circles, {
            strokeDashoffset: strokeLength * 2,
            transformOrigin: 'center',
          })
          .to(circles, {
            rotation: 90,
            strokeDashoffset: strokeLength,
          })
      }
    })
  }

  return () => {
    embla.destroy()
    each(events, (off) => off())
  }
})
