import { component } from 'picoapp'
import { add, remove, attr, on } from 'martha'
import choozy from 'choozy'
import gsap from 'gsap'

export default component((node, ctx) => {
  const { burger, burgerLines, close, closeLines } = choozy(node)

  const tl = gsap.timeline({
    paused: true,
    defaults: { duration: 1, ease: 'expo' },
  })

  on(node, 'click', () => {
    ctx.emit('menu:toggle', { isMenuOpen: !ctx.getState().isMenuOpen })
  })

  ctx.on('resize', ({ ww }) => {
    if (ww >= 960 && ctx.getState().isMenuOpen) {
      ctx.emit('menu:reset', { isMenuOpen: false })
    }
  })

  ctx.on('menu:reset', () => reset())
  ctx.on('menu:toggle', ({ isMenuOpen }) =>
    isMenuOpen ? activate() : deactivate(),
  )

  function activate() {
    attr(node, 'aria-expanded', 'true')

    tl.clear()
      .set(closeLines, { xPercent: -102 })
      .set(close, {
        autoAlpha: 1,
        x: -10,
        rotation: -20,
      })
      .to(
        burger,
        {
          x: 5,
          rotation: 2,
        },
        0,
      )
      .to(
        burgerLines,
        {
          xPercent: 102,
          stagger: 0.02,
        },
        0,
      )
      .to(
        close,
        {
          x: 0,
          rotation: 0,
        },
        0.4,
      )
      .to(
        closeLines,
        {
          duration: 1.2,
          xPercent: 0,
          stagger: -0.1,
        },
        0.4,
      )
      .restart()
  }

  function deactivate() {
    attr(node, 'aria-expanded', 'false')

    tl.clear()
      .set(burgerLines, {
        xPercent: -102,
      })
      .set(burger, {
        x: -10,
        rotation: -10,
      })
      .to(
        close,
        {
          x: 5,
          rotation: 4,
        },
        0,
      )
      .to(
        closeLines,
        {
          xPercent: 102,
          stagger: 0.02,
        },
        0,
      )
      .to(
        burger,
        {
          x: 0,
          rotation: 0,
        },
        0.4,
      )
      .to(
        burgerLines,
        {
          duration: 1.2,
          xPercent: 0,
          stagger: 0.04,
        },
        0.4,
      )
      .restart()
  }

  function reset() {
    attr(node, 'aria-expanded', 'true')

    tl.clear()
      .set(close, {
        x: 5,
        rotation: 4,
      })
      .set(closeLines, {
        xPercent: 102,
      })
      .set(burger, {
        x: 0,
        rotation: 0,
      })
      .set(burgerLines, {
        xPercent: 0,
      })
      .restart()
  }
})
