import { component } from 'picoapp'
import gsap from 'gsap'

export default component((video, ctx) => {
  video.load()

  const state = {
    videoProgress: 0,
  }

  ctx.on('initScrollTriggers', () => {
    gsap.fromTo(
      state,
      {
        videoProgress: 0,
      },
      {
        videoProgress: 1,
        ease: 'none',
        scrollTrigger: {
          trigger: video,
          start: 'top bottom',
          end: 'bottom top',
          scrub: true,
          invalidateOnRefresh: true,
        },
        onUpdate: () => {
          if (video.readyState >= 1) {
            video.currentTime = state.videoProgress * Math.floor(video.duration)
          }
        },
      },
    )
  })

  return () => {}
})
