import { component } from 'picoapp'
import choozy from 'choozy'
import { on } from 'martha'
import dcx from 'dcx'

export default component((node) => {
  const { input, label } = choozy(node)
  const labelCx = dcx(label)

  render(input.value.length)

  on(input, 'focus', () => {
    render(true)
  })

  on(input, 'blur', () => {
    if (input.value.length) return
    render(false)
  })

  function render(isLabelVisible) {
    labelCx({ 'is-filled': isLabelVisible })
  }
})
