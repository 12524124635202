import choozy from 'choozy'
import gsap from 'gsap'

export default function createNav(el) {
  let refs = choozy(el, 'js:nav-')
  let tl = gsap.timeline()

  return {
    el,
    refs,
    tl,
    hide() {
      let { dots } = refs

      tl.clear()
        .to(
          dots,
          {
            duration: 1,
            ease: 'expo',
            scale: 0,
            stagger: -0.025,
          },
          'hide',
        )
        .set(el, { autoAlpha: 0 })
    },
    show() {
      let { dots } = refs

      tl.clear()
        .set(dots, { scale: 0 })
        .set(el, { autoAlpha: 1 })
        .to(
          dots,
          {
            duration: 1,
            ease: 'expo',
            scale: 1,
            stagger: 0.025,
          },
          'show',
        )
        .set(dots, { clearProps: 'all' })
    },
  }
}
