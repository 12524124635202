import { component } from 'picoapp'
import choozy from 'choozy'
import { on } from 'martha'
import dcx from 'dcx'

export default component((node, ctx) => {
  const { select, label } = choozy(node)
  const labelCx = dcx(label)

  render()

  on(select, 'focus', () => {
    render(true)
  })

  on(select, 'change', () => {
    render(select.value.length)
  })

  on(select, 'blur', () => {
    render(select.value.length)
  })

  function render(bool) {
    labelCx({
      'is-filled': bool,
    })
  }
})
