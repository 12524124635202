import { component } from 'picoapp'
import choozy from 'choozy'
import { has, rect, remove } from 'martha'
import gsap from 'gsap'

export default component((node, ctx) => {
  const { layers, img: imgs } = choozy(node)

  Promise.all(
    imgs
      .filter(
        (img) =>
          getComputedStyle(img.closest('[data-img-wrap]')).display !== 'none',
      )
      .map(
        (img) =>
          new Promise((resolve) => {
            img.onload = resolve
          }),
      ),
  ).then(() => {
    remove(node, 'opacity-0')
  })

  ctx.on('resize', () => {
    gsap.set(node, {
      height: Math.max(...layers.map((layer) => rect(layer).height)),
    })
  })
})
