import { component } from 'picoapp'
import sqzbx from 'sqzbx'

export default component((node, ctx) => {
  const accordion = sqzbx(node, {
    collapsible: true,
    multiple: false,
    defaultIndex: node.hasAttribute('data-default-index')
      ? +node.dataset.defaultIndex
      : null,
    resize: false,
  })

  ctx.on('resize', () => accordion.resize())

  const unmount = accordion.mount()

  return () => {
    unmount()
  }
})
