import { component } from 'picoapp'
import choozy from 'choozy'
import { each, rect } from 'martha'
import SplitText from 'gsap/SplitText'
import gsap from 'gsap'

import createSidebar from './elements/sidebar'

export default component((node, ctx) => {
  const {
    headline,
    line,
    videoTrigger,
    video,
    captions,
    sidebar: sidebarEl,
  } = choozy(node)

  video.load()

  const sidebar = createSidebar(sidebarEl, ctx, videoTrigger)

  const sections = JSON.parse(node.dataset.sections)
  const frameCount = sections[sections.length - 1].end

  const state = {
    tl: null,
    videoProgress: 0,
  }

  ctx.on('resize', () => {
    videoTrigger.rect = rect(videoTrigger)

    headline.split && headline.split.revert()
    headline.split = new SplitText(headline, {
      type: 'words',
      linesClass: 'will-change-transform',
    })

    each([captions].flat(), (el) => {
      el.outerSplit && el.outerSplit.revert()
      el.innerSplit && el.innerSplit.revert()

      el.innerSplit = new SplitText(el.children, {
        type: 'lines',
        linesClass: 'will-change-transform',
      })

      el.outerSplit = new SplitText(el.children, {
        type: 'lines',
        linesClass: 'overflow-hidden',
      })
    })

    createScrollSequence()
  })

  function createScrollSequence() {
    if (state.tl) {
      state.tl.revert()
    }

    state.tl = gsap.timeline({
      defaults: {
        ease: 'none',
        duration: 1,
      },
      scrollTrigger: {
        trigger: videoTrigger,
        start: 'top top',
        end: 'bottom bottom',
        scrub: true,
        invalidateOnRefresh: true,
      },
    })

    each(captions, (caption, i) => {
      state.tl
        .set(caption.innerSplit.lines, { yPercent: i === 0 ? 0 : 100 })
        .set(caption, { autoAlpha: 1 })
    })

    each(sections, ({ start, end }, i) => {
      const currentCaption = captions[i]
      const nextCaption = captions?.[i + 1]
      const isFirstSection = i === 0

      state.tl.fromTo(
        state,
        { videoProgress: start / frameCount },
        {
          videoProgress: end / frameCount,
          duration: 1,
          onUpdate: () => {
            if (video.readyState >= 1) {
              video.currentTime =
                state.videoProgress * Math.floor(video.duration)
            }
          },
        },
        i,
      )

      if (isFirstSection) {
        state.tl.fromTo(
          line,
          {
            yPercent: 0,
          },
          {
            yPercent: -100,
            duration: 1,
          },
          0,
        )
      }

      if (nextCaption) {
        state.tl
          .fromTo(
            currentCaption.innerSplit.lines,
            {
              yPercent: 0,
            },
            {
              yPercent: -100,
              ease: 'expo.inOut',
              duration: 0.27,
              stagger: {
                amount: 0.03,
              },
            },
            i + 0.75,
          )
          .fromTo(
            nextCaption.innerSplit.lines,
            {
              yPercent: 100,
            },
            {
              yPercent: 0,
              ease: 'expo',
              duration: 0.27,
              stagger: {
                amount: 0.03,
              },
            },
            '>-=0.1',
          )
      }
    })

    video.currentTime = 0
  }

  let currentTime = 0
  let previousIndex = -1
  let currentIndex = -1

  ctx.on('tick', ({ isAutoScrolling }) => {
    currentTime = state.tl.time()
    currentIndex = Math.floor(currentTime)

    if (currentIndex !== previousIndex && currentIndex !== sections.length) {
      !isAutoScrolling && ctx.emit('sidebar:update', null, currentIndex)
    }

    previousIndex = currentIndex
  })

  return () => {
    sidebar.unmount()
  }
})
