import { component } from 'picoapp'
import EmblaCarousel from 'embla-carousel'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import gsap from 'gsap'
import { qs, qsa, round, lerp } from 'martha'
import { hover } from '../lib/hover'

export default component((node, ctx) => {
  const cursor = qs('.js-cursor', node.parentNode)
  const links = qsa('.js-links', node)

  const wheelGestures = WheelGesturesPlugin({
    element: node,
    forceWheelAxis: 'x',
  })

  const embla = EmblaCarousel(
    node,
    {
      loop: true,
      dragFree: true,
    },
    [wheelGestures],
  )

  const offHover = hover(links, {
    enter: () => {
      gsap.killTweensOf(cursor, 'autoAlpha')
      gsap.to(cursor, {
        autoAlpha: 1,
        duration: 0.7,
        ease: 'expo',
      })
    },
    leave: () => {
      gsap.killTweensOf(cursor, 'autoAlpha')
      gsap.to(cursor, {
        autoAlpha: 0,
        duration: 0.7,
        ease: 'expo',
      })
    },
  })

  let x = 0
  let y = 0

  ctx.on('tick', ({ mouseX, mouseY }) => {
    x = round(lerp(x, mouseX, 0.12), 3)
    y = round(lerp(y, mouseY, 0.12), 3)
    gsap.set(cursor, { x, y })
  })

  return () => {
    embla.destroy()
    offHover()
  }
})
