import { qs, toggle, rect, remove } from 'martha'
import { component } from 'picoapp'

export default component((node, ctx) => {
  let firstModule = null
  let hasHero = false
  let cx = 'is-transparent'

  ctx.on('afterLeave', () => {
    hasHero = false
  })

  ctx.on('beforeEnter', (_, ev) => {
    firstModule = qs('[data-module]', ev.to)
    hasHero = firstModule?.dataset?.module?.includes?.('hero') ?? false
  })

  ctx.on('resize', () => {
    if (!hasHero) return
    firstModule.rect = rect(firstModule)
    node.rect = rect(node)
  })

  ctx.on('tick', () => {
    if (hasHero) {
      const threshold = firstModule.dataset.module === 'primary-hero' ? 0.5 : 1

      toggle(
        node,
        cx,
        window.scrollY <
          firstModule.rect.height * threshold - node.rect.height / 2,
      )
    } else {
      remove(node, cx)
    }
  })
})
