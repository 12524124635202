import { component } from 'picoapp'
import choozy from 'choozy'
import { add, remove, on, toggle, each, index, qsa } from 'martha'
import EmblaCarousel from 'embla-carousel'

export default component((node, ctx) => {
  const { container, controls, next, prev, pagination } = choozy(node)
  let events = []

  const embla = EmblaCarousel(container, {
    align: 'start',
    containScroll: 'trimSnaps',
    breakpoints: JSON.parse(node.dataset.breakpoints),
  })

  embla.on('init', () => render())
  embla.on('reInit', () => render())
  embla.on('select', () => handleSelect())

  function handleSelect() {
    if (embla.canScrollNext()) {
      next.disabled = false
      remove(next, 'opacity-25 pointer-events-none')
    } else {
      next.disabled = true
      add(next, 'opacity-25 pointer-events-none')
    }

    if (embla.canScrollPrev()) {
      prev.disabled = false
      remove(prev, 'opacity-25 pointer-events-none')
    } else {
      prev.disabled = true
      add(prev, 'opacity-25 pointer-events-none')
    }

    each(pagination.btns, (btn, i) =>
      toggle(btn, '!opacity-100', i === embla.selectedScrollSnap()),
    )
  }

  function render() {
    pagination.innerHTML = embla
      .scrollSnapList()
      .map(
        (_, i) =>
          `<button
          aria-label="Go to ${i}"
          class="w-6 h-6 bg-black rounded-full opacity-50 transition-opacity ease-out-expo duration-700 js-btns"
        ></button>`,
      )
      .join('')

    pagination.btns = qsa('.js-btns', pagination)

    events.map((off) => off())

    events = [
      on(prev, 'click', () => embla.scrollPrev()),
      on(next, 'click', () => embla.scrollNext()),
      on(pagination.btns, 'click', ({ currentTarget: t }) =>
        embla.scrollTo(index(t)),
      ),
    ]

    handleSelect()

    toggle(controls, 'hidden', embla.scrollSnapList().length === 1)
  }

  return () => {
    embla.destroy()
    events.map((off) => off())
  }
})
