import { component } from 'picoapp'
import choozy from 'choozy'
import { on, add } from 'martha'
import gsap from 'gsap'
import { newsletterSubscribe } from '../lib/newsletter-subscribe'

export default component((node, ctx) => {
  let { form, message } = choozy(node)
  let isSubmitting = false

  let offSubmit = on(form, 'submit', (ev) => {
    ev.preventDefault()

    if (isSubmitting) return
    isSubmitting = true

    showLoader()

    newsletterSubscribe({
      audienceId: '97854a1ed7',
      email: form.email.value,
    })
      .then(() => {
        // app.ga.send('event', { ec: 'Lead', ea: 'Newsletter Signup' })
        showMessage()
      })
      .catch(() => {
        message.textContent = message.dataset.error
        showMessage()
      })

    function showLoader() {
      add(form, 'opacity-40 pointer-events-none')
    }

    function showMessage() {
      gsap.to(form, {
        duration: 0.5,
        scale: 0.95,
        autoAlpha: 0,
        ease: 'quint',
      })

      gsap.set(message, { y: 30 })

      gsap.to(message, {
        duration: 1,
        delay: 0.25,
        autoAlpha: 1,
        y: 15,
        ease: 'quint',
      })
    }
  })

  return () => {
    offSubmit()
  }
})
