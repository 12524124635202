import { component } from 'picoapp'
import choozy from 'choozy'
import Cookies from 'js-cookie'
import { add, on, remove } from 'martha'
import gsap from 'gsap'

export default component((node, ctx) => {
  let REGION = null
  let isOpen = false

  const {
    closeButton,
    lines,
    preferences,
    form,
    statisticsCheckbox,
    marketingCheckbox,
  } = choozy(node)

  const params = new URLSearchParams(window.location.search)

  const tl = gsap.timeline({
    paused: true,
    defaults: { ease: 'expo', duration: 1 },
  })

  on(closeButton, 'click', hide)

  on(form, 'submit', (ev) => {
    ev.preventDefault()

    if (statisticsCheckbox.checked) {
      Cookies.set('accept-statistics-cookies', true, { expires: 365 })
    } else {
      Cookies.remove('accept-statistics-cookies')
    }

    if (marketingCheckbox.checked) {
      Cookies.set('accept-marketing-cookies', true, { expires: 365 })
    } else {
      Cookies.remove('accept-marketing-cookies')
    }

    deployCookies()
    hide()
  })

  if (hasAcceptStatisticsCookies()) {
    statisticsCheckbox.checked = true
  }

  if (hasAcceptMarketingCookies()) {
    marketingCheckbox.checked = true
  }

  function hide() {
    if (!isOpen) return
    isOpen = false

    Cookies.set('hide-cookie-notice', 'true', { expires: 365 })

    tl.clear()
      .to(lines, { xPercent: 102, stagger: 0.02 }, 0)
      .to(node, { yPercent: 100, ease: 'expo.inOut' }, 0)
      .set(node, { autoAlpha: 0 })
      .restart()
  }

  function show() {
    if (isOpen) return
    isOpen = true

    if (REGION === 'EU') {
      add(closeButton, 'hidden')
      remove(preferences, 'hidden')
    } else {
      remove(closeButton, 'hidden')
      add(preferences, 'hidden')
    }

    tl.clear()
      .set(lines, { xPercent: 0 })
      .set(node, { yPercent: 100, autoAlpha: 1 })
      .to(node, { yPercent: 0 }, 0)
      .restart()
  }

  fetch('https://get.geojs.io/v1/ip/country.json')
    .then((res) => res.json())
    .then((data) => {
      switch (data.country) {
        case 'BE':
        case 'BG':
        case 'CZ':
        case 'DK':
        case 'DE':
        case 'EE':
        case 'IE':
        case 'GR':
        case 'ES':
        case 'FR':
        case 'HR':
        case 'IT':
        case 'CY':
        case 'LV':
        case 'LT':
        case 'LU':
        case 'HU':
        case 'MT':
        case 'NL':
        case 'AT':
        case 'PL':
        case 'PT':
        case 'RO':
        case 'SI':
        case 'SK':
        case 'FI':
        case 'SE':
          REGION = 'EU'
          break
      }

      if (params.has('test_region')) {
        REGION = params.get('test_region')
      }

      let offTick = ctx.on('tick', ({ wh }) => {
        if (window.scrollY < wh) return
        offTick()

        if (Cookies.get('hide-cookie-notice') !== 'true') {
          show()
        }
      })

      deployCookies()
    })

  function deployCookies() {
    if (REGION === 'EU') {
      hasAcceptStatisticsCookies() && deployStatisticsCookies()
      hasAcceptMarketingCookies() && deployMarketingCookies()
    } else {
      deployStatisticsCookies()
      deployMarketingCookies()
    }
  }

  on(document, 'click', (ev) => {
    if (ev.target.closest('[data-cookie-preferences-link]')) {
      if (isOpen) {
        hide()
      } else {
        show()
      }
    }
  })

  let statisticsDeployed = false
  function deployStatisticsCookies() {
    if (statisticsDeployed) return
    statisticsDeployed = true
    ;(function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r
      ;(i[r] =
        i[r] ||
        function () {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(
      window,
      document,
      'script',
      '//www.google-analytics.com/analytics.js',
      'ga',
    )

    ga('create', 'UA-55326119-8', 'auto')
    ga('send', 'pageview')
  }

  let marketingDeployed = false
  function deployMarketingCookies() {
    if (marketingDeployed) return
    marketingDeployed = true
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      })
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', 'GTM-WMT634V')
  }

  function hasAcceptMarketingCookies() {
    return Cookies.get('accept-marketing-cookies') === 'true'
  }

  function hasAcceptStatisticsCookies() {
    return Cookies.get('accept-statistics-cookies') === 'true'
  }
})
