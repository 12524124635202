import { component } from 'picoapp'
import { io } from '../lib/io'

export default component((video, ctx) => {
  const offIo = io(video, {
    enter: () => video.play(),
    exit: () => video.pause(),
  })

  return () => {
    offIo()
  }
})
