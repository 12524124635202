import { picoapp } from 'picoapp'
import { size } from 'martha'
import { isDevice } from './lib/is-device'

import grid from './components/grid'
import header from './components/header'
import dropdownMenu from './components/dropdown-menu'
import menuToggle from './components/menu-toggle'
import menu from './components/menu'
import accordion from './components/accordion'
import button from './components/button'
import cookieNotice from './components/cookie-notice'
import video from './components/video'
import img from './components/img'
import primaryHero from './components/primary-hero'
import parallax from './components/parallax'
import rotate from './components/rotate'
import marquee from './components/marquee'
import storiesSlider from './components/stories-slider'
import swatchSlider from './components/swatch-slider'
import pressCarousel from './components/press-carousel'
import newsletter from './components/newsletter'
import carousel from './components/carousel'
import share from './components/share'
import videoPlayer from './components/video-player'
import inputText from './components/input-text'
import inputSelect from './components/input-select'
import form from './components/form'
import myceliumGrowthSequence from './components/mycelium-growth-sequence/mycelium-growth-sequence'
import scrollSequence from './components/scroll-sequence'

const components = {
  header,
  dropdownMenu,
  menuToggle,
  menu,
  accordion,
  button,
  cookieNotice,
  video,
  img,
  primaryHero,
  parallax,
  rotate,
  marquee,
  storiesSlider,
  swatchSlider,
  pressCarousel,
  newsletter,
  carousel,
  share,
  videoPlayer,
  inputText,
  inputSelect,
  form,
  myceliumGrowthSequence,
  scrollSequence,
}

if (process.env.NODE_ENV !== 'production') {
  components.grid = grid
}

const state = {
  ...size(),
  isMenuOpen: false,
  isDevice: isDevice(),
  time: 0,
  deltaTime: 0,
  frame: 0,
  mouseX: 0,
  mouseY: 0,
  lastScrollY: window.scrollY,
  scrollY: window.scrollY,
  scrollDirection: 1,
  isAutoScrolling: false,
}

export default picoapp(components, state)
