import gsap from 'gsap'

export const fade = {
  async enter({ to }) {
    await gsap
      .to(to, {
        autoAlpha: 1,
        duration: 1,
        ease: 'expo',
      })
      .then()
  },
  async leave({ from }) {
    await gsap
      .to(from, {
        autoAlpha: 0,
        duration: 1,
        ease: 'expo',
        onComplete: () => {
          window.scroll(0, 0)
          from?.remove()
        },
      })
      .then()
  },
}
