import { component } from 'picoapp'
import choozy from 'choozy'
import { on } from 'martha'
import gsap from 'gsap'
import { signal } from '../lib/signal'

export default component((node, ctx) => {
  const { btn, indicator, menu } = choozy(node)

  const tl = gsap.timeline({
    paused: true,
    defaults: {
      duration: 1,
      ease: 'expo',
    },
  })

  let [isOpen, setOpen] = signal(false, (isOpen) => (isOpen ? open() : close()))

  on(btn, 'click', () => setOpen(!isOpen()))
  ctx.on('beforeLeave', () => isOpen() && setOpen(false))

  on(document, 'click', (ev) => {
    if (isOpen() && ev.target.closest('[data-dropdown]') !== node) {
      setOpen(false)
    }
  })

  on(window, 'keydown', ({ key }) => {
    if (isOpen() && key === 'Escape') {
      setOpen(false)
    }
  })

  function open() {
    tl.clear()
      .to(
        menu,
        {
          autoAlpha: 1,
        },
        0,
      )
      .to(
        indicator,
        {
          rotation: 90,
        },
        0,
      )
      .restart()
  }

  function close() {
    tl.clear()
      .to(
        menu,
        {
          autoAlpha: 0,
        },
        0,
      )
      .to(
        indicator,
        {
          rotation: 0,
        },
        0,
      )
      .restart()
  }
})
